import React, { Component } from "react";
import dashboardExample from './images/dashboardExample.png';

class Dashboard extends Component {
  render() {
    return (
      <div>
        <h2>Company Dashboard</h2>
        <img src={dashboardExample} alt="Dashboard Example"/>
      </div>
    );
  }
}

export default Dashboard;