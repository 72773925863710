import React, { Component } from "react";
import {
  Route,
  NavLink,
  HashRouter
} from "react-router-dom";
import Dashboard from "./Dashboard";
import Alerts from "./Alerts";
import Inventory from "./Inventory";
import Maintenance from "./Maintenance";
import Monitoring from "./Monitoring";
import Inspections from "./Inspections";
import Settings from "./Settings";
import logo from './images/ATIS-logo-final-3.svg';
import searchExample from './images/searchExample.png';
import profileExample from './images/profileExample.png';
import homeIcon from './images/home_icon.png';
import alertsIcon from './images/alerts_icon.png';
import inspectionsIcon from './images/inspections_icon.png';
import maintenanceIcon from './images/maintenance_icon.png';
import monitoringIcon from './images/monitoring_icon.png';
import inventoryIcon from './images/inventory_icon.png';
import settingsIcon from './images/settings_icon.png';


class Main extends Component {
  render() {
    return (
      <HashRouter>
        <div class="grid-container">
          <div class="logopane"><img src={logo} style={{ height: 53, width: 150 }} alt="ATIS SaaS"/>
            <div class="searchpane">
               <img src={searchExample} alt="Search Example"/>

              <div class="profilepane">
              <img src={profileExample} alt="Profile Example"/>
              </div>
            </div>
          </div>
          <div class="mainpane">
            <div class="contentpane">
                <Route exact path="/" component={Dashboard}/>
                <Route path="/alerts" component={Alerts}/>
                <Route path="/inspections" component={Inspections}/>
                <Route path="/inventory" component={Inventory}/>
                <Route path="/maintenance" component={Maintenance}/>
                <Route path="/monitoring" component={Monitoring}/>
                <Route path="/settings" component={Settings}/>
            </div>
            <div class="menupane">
            <ul>
                <li><img src={homeIcon} alt="Dashboard"/><NavLink exact to="/">Dashboard</NavLink></li>
                <li><img src={alertsIcon} alt="Alerts"/><NavLink to="/alerts">Alerts</NavLink></li>
                <li><img src={inspectionsIcon} alt="Inspections"/><NavLink to="/inspections">Inspections</NavLink></li>
                <li><img src={inventoryIcon} alt="Inventory"/><NavLink to="/inventory">Inventory</NavLink></li>
                <li><img src={maintenanceIcon} alt="Maintenance"/><NavLink to="/maintenance">Maintenance</NavLink></li>
                <li><img src={monitoringIcon} alt="Monitoring"/><NavLink to="/monitoring">Monitoring</NavLink></li>
                <li><img src={settingsIcon} alt="Settings"/><NavLink to="/settings">Settings</NavLink></li>
            </ul>
            </div>
          </div>
        </div>
      </HashRouter>
    );
  }
}

export default Main;